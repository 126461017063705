import React, {useEffect, useState} from 'react';
import {graphql} from 'gatsby';
import {Swiper, SwiperSlide} from 'swiper/react';

import {Breadcrumb, Card, Layout, Seo, Share} from '@app/components';
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import {db} from '../../firebase';

type PageTemplatePropTypes = {
  data: {
    allMarkdownRemark: {
      nodes: {
        excerpt: string;
        frontmatter: {
          title: string;
          author: string;
          cover: any;
          imageUrl: string;
          date: any;
        };
      }[];
    };
  };
  pageContext: {
    author: string;
  };
};

const PageTemplate: React.FC<PageTemplatePropTypes> = ({data, pageContext}) => {
  const [authorPost, setAuthorPost] = useState(data.allMarkdownRemark.nodes);
  const [isRender, setIsRender] = useState(false);

  useEffect(() => {
    getAuthorPostData();
  }, []);

  const getAuthorPostData = () => {
    const mappingData: any = [];
    const unSub = async () => {
      const q = query(
        collection(db, 'posts'),
        where('language', '==', pageContext.language),
        where('author', '==', pageContext.author),
        orderBy('createAt', 'desc'),
        limit(15),
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        mappingData.push({
          excerpt: doc.data().article,
          frontmatter: {
            title: doc.data().title,
            author: doc.data().author,
            date: new Date(doc.data().createAt.seconds * 1000).toISOString(),
            imageUrl: doc.data().imageUrl,
          },
        });
      });

      const showingPosts = [
        ...mappingData,
        ...data.allMarkdownRemark.nodes,
      ].slice(0, 15);

      showingPosts.sort(function (a, b) {
        var keyA = new Date(a.frontmatter.date),
          keyB = new Date(b.frontmatter.date);
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });

      setAuthorPost(showingPosts);
      setIsRender(true);
      //setIsLoad(true);
    };

    unSub();
  };

  const posts = data.allMarkdownRemark.nodes;

  return (
    <Layout>
      <Seo title={pageContext.author} />
      <Breadcrumb title={pageContext.author} />
      <div
        className={`container mx-auto px-4 py-20 lg:px-0 xl:max-w-container-xl ${
          isRender ? 'content-block' : 'content-none'
        }`}
      >
        <div className="grid lg:grid-cols-2 lg:gap-x-10 gap-y-20">
          {authorPost.slice(0, 2).map((post) => (
            <Card
              title={post.frontmatter.title}
              date={post.frontmatter.date}
              description={post.excerpt}
              author={post.frontmatter.author}
              cover={post.frontmatter.cover?.childImageSharp?.fluid}
              imageUrl={post.frontmatter.imageUrl}
              key={post.frontmatter.title}
            />
          ))}
        </div>
        <div className="grid lg:grid-cols-3 lg:gap-x-10 gap-y-20 mt-20">
          {authorPost.slice(2, 11).map((post) => (
            <Card
              title={post.frontmatter.title}
              description={post.excerpt}
              date={post.frontmatter.date}
              author={post.frontmatter.author}
              cover={post.frontmatter.cover?.childImageSharp?.fluid}
              imageUrl={post.frontmatter.imageUrl}
              key={post.frontmatter.title}
            />
          ))}
        </div>
      </div>

      {authorPost.slice(11).length > 0 && (
        <div className="py-20">
          <Swiper
            spaceBetween={20}
            slidesPerView={1}
            breakpoints={{
              '768': {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              '1024': {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              '1280': {
                slidesPerView: 9,
                spaceBetween: 20,
              },
            }}
          >
            {authorPost.slice(11).map((post) => (
              <SwiperSlide key={post.frontmatter.title}>
                <Card
                  title={post.frontmatter.title}
                  date={post.frontmatter.date}
                  cover={post.frontmatter.cover.childImageSharp.fluid}
                  imageUrl={post.frontmatter.imageUrl}
                  author={post.frontmatter.author}
                  isSmall
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}

      <div className="container mx-auto px-4 py-2.5 lg:px-0 xl:max-w-container-xl">
        <Share title={pageContext.author} />
      </div>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query ($author: String!, $language: String!) {
    allMarkdownRemark(
      filter: {frontmatter: {author: {eq: $author}, language: {eq: $language}}}
    ) {
      nodes {
        excerpt(pruneLength: 140)
        frontmatter {
          title
          author
          date
          cover {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
